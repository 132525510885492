'use client';

import { useState } from 'react';
import Link from 'next/link';

const ApplicationGuide = () => {
    const [currentCard, setCurrentCard] = useState('professional');

    return (
        <div>
            <h2 className="text-3xl font-bold mb-6 text-center">Application Guide</h2>
            <p className="text-lg mb-6">Follow the steps below according to the type of application you're making: as a Professional or as a NID student.</p>

            {currentCard === 'professional' && (
                // <div className="bg-[#f3f3f3] shadow-md rounded-lg p-6 mb-6 overflow-y-auto h-64 scrollbar-hide scrollable-section-hide">
                <div>
                    <h3 className="text-2xl font-semibold mb-4">For Professional Programmes</h3>
                    <ul className="list-disc list-inside space-y-4">
                        <li><strong>Start Your Application:</strong> Click the "Professional" button on the main page. This will redirect you to the application page specifically for professionals.</li>
                        <li><strong>Account Verification and Payment:</strong> After registering, you will need to verify your account and make the required payment as part of the application process.</li>
                        <li><strong>Application Status and Fees:</strong> Log in <Link href='/aspirant/professional/login' className='text-orange-500 font-bold'>here</Link> to view your admission status and find information about the necessary steps to finalize your admission process.</li>
                        <li><strong>Access Your Dashboard:</strong> Once the admission and payment process is complete, return to the main page and log in as a student. You will then be able to access your student dashboard where you can find all relevant information and resources.</li>
                    </ul>
                    </div>
                // {/* </div> */}
            )}

            {currentCard === 'nid' && (
                // <div className="bg-[#f3f3f3] shadow-md rounded-lg p-6 overflow-y-auto h-64 scrollbar-hide scrollable-section-hide">
                    <div>
                    <h3 className="text-2xl font-semibold mb-4">For NID Programmes</h3>
                    <ul className="list-disc list-inside space-y-4">
                        <li><strong>Registration:</strong> Click on the "PUTME Registration" button. This action will start your application process for a degree program.</li>
                        <li><strong>Admission Status and Letter Upload:</strong> Once you have logged in at <Link href='/aspirant/nid/login' className='text-orange-500 font-bold'>login</Link>, wait for your admission status. If accepted, upload your admission letter through the provided portal and pay the required fees.</li>
                        <li><strong>Access Your Dashboard:</strong> After completing the above steps, you are now considered a full student. Log in to access your dashboard, where all essential resources and information are available to support your academic journey.</li>
                    </ul>
                    </div>
                // {/* </div> */}
            )}

            {/* Button to Switch Between Cards */}
            <div className="flex justify-center mt-8 space-x-4">
                <button
                    onClick={() => setCurrentCard('professional')}
                    className={`w-6 h-6 rounded-full ${currentCard === 'professional' ? 'bg-orange-500' : 'bg-transparent border-2 border-orange-500'}`}
                ></button>
                <button
                    onClick={() => setCurrentCard('nid')}
                    className={`w-6 h-6 rounded-full ${currentCard === 'nid' ? 'bg-orange-500' : 'bg-transparent border-2 border-orange-500'}`}
                ></button>
            </div>
        </div>
    )
}

export default ApplicationGuide;